/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ContentPageTemplate from "../../templates/content-page";
import KeyValuePair from "../../components/key-value-pair";
const MDXLayout = ContentPageTemplate;
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "Berufliche Vita"), "\n", React.createElement(KeyValuePair, {
    one: "1986 - 1994",
    two: "Studium Humanmedizin"
  }), "\n", React.createElement(KeyValuePair, {
    one: "1994 - 2003",
    two: "Psychiatrische Klinik der Universitätsklinik Mainz (Assistenzarzt)"
  }), "\n", React.createElement(KeyValuePair, {
    one: "2003 - 2012",
    two: "LVR Klinik Düsseldorf (Oberarzt)"
  }), "\n", React.createElement(KeyValuePair, {
    one: "2012-2019",
    two: "Klinik für Psychosomatische Medizin und Psychotherapie LVR Klinik Essen (Assistenzarzt)"
  }), "\n", React.createElement(KeyValuePair, {
    one: "seit 2019",
    two: "Niederlassung in eigener Praxis"
  }), "\n", React.createElement(_components.h3, null, "Facharztanerkennung"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Facharzt für Psychiatrie und Psychotherapie"), "\n", React.createElement(_components.li, null, "Facharzt für Psychosomatische Medizin und Psychotherapie"), "\n"));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
