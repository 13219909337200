import classNames from "classnames";
import React from "react";

export interface KayValuePairProps {
  one: string | React.ReactNode;
  two: string | React.ReactNode;
  space?: "lg" | "md" | "sm" | "none";
  keyClassName?: string;
  valueClassName?: string;
}

const KeyValuePair: React.FC<KayValuePairProps> = ({
  one,
  two,
  space = "sm",
  keyClassName,
  valueClassName,
}) => {
  return (
    <dl
      className={classNames("grid grid-cols-12", {
        "py-0": space === "none",
        "py-1": space === "sm",
        "py-2": space === "md",
        "py-4": space === "lg",
      })}
    >
      <dt className={classNames("col-span-4 hyphens-auto mr-1", keyClassName)}>
        {one}
      </dt>
      <dd className={classNames("col-span-8", valueClassName)}>{two}</dd>
    </dl>
  );
};

export default KeyValuePair;
